import { ApiDataError, Filter } from "../types/types";

export function convertNumToString(number) {
  return number.toLocaleString("en-US");
}

export function formatPercentage(number) {
  return Math.round(number * 100);
}

export function formatDate(date) {
  if (!date) return null;

  return date.toISOString().slice(0, 10);
}

export function formatHygieneStarsData(chartHygieneStars: any) {
  let transformedHygieneStarsData: any[] = [];
  let keys = Object.keys(chartHygieneStars);

  for (let i = 0; i < keys.length; i++) {
    transformedHygieneStarsData.push([
      chartHygieneStars[keys[i]].name,
      chartHygieneStars[keys[i]].group_name,
      chartHygieneStars[keys[i]].total_opportunities,
      formatPercentage(chartHygieneStars[keys[i]].performance),
    ]);
  }
  return transformedHygieneStarsData;
}

export function formatTransformedPerformanceData(chartPerformance) {
  return chartPerformance.map((unit) => {
    return [unit.name, unit.opportunities, formatPercentage(unit.performance)];
  });
}

export function formatPerformanceWithPercent(chartPerformance: any[]) {
  if (!chartPerformance) return [];
  return chartPerformance.map((item) => {
    return [item.name, item.opportunities, item.performance + "%"];
  });
}

export const getColor = (percentage: number) => {
  if (percentage >= 0 && percentage < 30) {
    return "#FF6978";
  } else if (percentage >= 30 && percentage < 60) {
    return "#FFCB77";
  } else if (percentage >= 60 && percentage < 80) {
    return "#9FD356";
  } else if (percentage >= 80 && percentage <= 100) {
    return "#00A6FB";
  } else {
    return "#E0E0E0";
  }
};

export function getFilterMapper() {
  const filterMapper = {
    group_ids: "groups",
    unit_ids: "units",
    room_config_ids: "rooms",
    asset_type_ids: "type",
    asset_model_ids: "model",
    facility_ids: "facilities",
    customer_ids: "customers",
    tag_ids: "Unit Types",
    exposure_type: "Exposure Type",
    user_ids: "users",
    blueprint_realtime_shift_selector: "shift",
    aggregation: "aggregation",
  };

  return filterMapper;
}

export function navigateToStaffPage(id, rowData) {
  const entries = Object.entries(id as Object);
  for (const [key, value] of entries) {
    const updatedString = (value as string).replace(/\s*\[.*?\]\s*$/, "");
    if (updatedString === rowData) {
      const id = key;
      window.location.pathname = `/HH/staff/${id}`;
    }
  }
}

export const CHSHConstants = {
  colors: {
    danger: "#b91c10",
    primary: "#00ABE5",
    darkGray: "#344054",
    gray: "#475467",
    lightGray: "#eaecf0",
    // 100%
    fullGreen: "#006737",
    // 0%
    fullRed: "#A50026",
    // 50%
    lightGreen: "#F9F7AE",
    // 25%
    lightRed: "#FBAB62",
    primarySS: "#0F1828",
    primaryTone0: "#53389E",
    primaryTone6: "#F8F5FE",
    purple: "#7F56D9",
    white: " #ffffff",
    yellow: "#FCE02C",
    buttonPrimary: "#6a41c6",
  },
  performanceCutoffs: {
    bad: 0.3,
    good: 0.8,
    ok: 0.6,
    perfect: 1,
    zero: 0,
  },
};

export function removeDomainAndLeadingSlashFromURL(url) {
  if (!url) return "";
  const urlObj = new URL(url);
  const path = urlObj.pathname;
  return path.startsWith("/")
    ? path.slice(1) + urlObj.search
    : path + urlObj.search;
}

export function hasSingleCustomerSelected(filters: Filter[]): boolean {
  if (!filters || !filters.length) return false;
  const customerFilter = filters.find((filter) => filter.name === "customers");
  if (!customerFilter) return true;
  return customerFilter?.selection.length === 1;
}

export function getApiUrlFromGetRequest(dataApiUrl, error) {
  if (error && Object.keys(error).length === 0) {
    const keyToFind = "apiUrl";

    const isolateUrls = Object.keys(dataApiUrl).map((key) => ({
      [key]: dataApiUrl[key][keyToFind],
    }));

    const valuesArray = isolateUrls.map((obj) => Object.values(obj)[0]);

    const apiUrl = valuesArray;

    const apiUrlWithoutDomain = apiUrl.map((url) =>
      removeDomainAndLeadingSlashFromURL(url)
    );

    return apiUrlWithoutDomain;
  } else {
    return [];
  }
}

export function convertSecondsToHoursMinutesSeconds(
  seconds: number | null
): string {
  if (!seconds) return "0s";
  const hours = Math.floor(seconds / 3600);

  if (hours > 0) {
    // when we have hours, round to the nearest minute and do not display seconds
    const minutes = Math.round((seconds - hours * 3600) / 60);

    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  }

  // format minutes and seconds for times less than 1 hour
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds - minutes * 60;

  if (minutes > 0) {
    return secondsLeft > 0 ? `${minutes}m ${secondsLeft}s` : `${minutes}m`;
  }

  return `${secondsLeft}s`;
}

export function isEmptyArray(array: any[] | ApiDataError) {
  return Array.isArray(array) && array.length === 0;
}

export function dateDiffInDays(date1: Date, date2: Date): number {
  // Get the number of milliseconds in a day
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  const diffMilliseconds = Math.abs(date1.getTime() - date2.getTime());

  // Convert the difference in milliseconds to days
  const diffDays = Math.round(diffMilliseconds / millisecondsPerDay);

  return diffDays;
}

export function formatCalendarDate(date: Date): string {
  const dateObj = new Date(date);
  dateObj.setHours(0, 0, 0, 0);
  const updatedDateStr = dateObj.toDateString();
  return updatedDateStr;
}

export const pastSevenDays = (startDate: Date, endDate: Date) => {
  startDate.getDate();
  endDate.setDate(endDate.getDate() - 7);
};

export const pastFourteenDays = (startDate: Date, endDate: Date) => {
  startDate.getDate();
  endDate.setDate(endDate.getDate() - 14);
};

export const pastThirtyDays = (startDate: Date, endDate: Date) => {
  startDate.getDate();
  endDate.setDate(endDate.getDate() - 30);
};

export const lastWeek = () => {
  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const mostRecentSunday = new Date(today);
  mostRecentSunday.setDate(today.getDate() - currentDayOfWeek);
  const startDate = new Date(mostRecentSunday);
  const endDate = new Date(mostRecentSunday);
  endDate.setDate(mostRecentSunday.getDate() - 1); // Sunday before last
  startDate.setDate(mostRecentSunday.getDate() - 7); // Previous Sunday
  return { startDate, endDate };
};

export const lastMonth = () => {
  const today = new Date();
  const firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return { startDate: firstDayOfLastMonth, endDate: lastDayOfLastMonth };
};

export const lastThreeMonths = () => {
  const today = new Date();

  const firstDayOfThreeMonthsAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    1
  );

  const lastDayOfOneMonthAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  );

  return { startDate: firstDayOfThreeMonthsAgo, endDate: lastDayOfOneMonthAgo };
};

export const lastSixMonths = () => {
  const today = new Date();

  const firstDayOfSixMonthsAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    1
  );

  const lastDayOfOneMonthAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  );

  return { startDate: firstDayOfSixMonthsAgo, endDate: lastDayOfOneMonthAgo };
};

export function convertToXYPairs(data: number[]) {
  return data?.map((value: number, index: number) => ({
    x: index === 0 ? 5 : index * 10 + 5,
    y: value,
  }));
}

export function formatAbbreviatedNumber(num: number) {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return num.toString();
  }
}

export function convertDecimalHoursToHoursMinutes(decimalHours: number) {
  const hours = Math.floor(decimalHours);
  const decimalPart = decimalHours - hours;
  const minutes = Math.round(decimalPart * 60);

  if (hours === 0) {
    return minutes + " minutes";
  } else {
    return hours + " hours and " + minutes + " minutes";
  }
}

export function formatNumberWithCommas(num: number) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isTrendingDownward = (percentages: string | any[]) => {
  if (percentages.length < 2) return false;
  return percentages[0] > percentages[percentages.length - 1];
};

export function stripWhiteSpace(value: string) {
  return value.replace(/\s/g, "");
}

export function convertDateRangeFromString(dateRange: string) {
  const [startDateStr, endDateStr] = dateRange
    .split("–")
    .map((date) => date.trim());

  function formatDateString(dateString: string | number | Date) {
    const dateObj = new Date(dateString);

    const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
    const dd = String(dateObj.getDate()).padStart(2, "0");
    const yy = String(dateObj.getFullYear()).slice(-2);

    return `${mm}/${dd}/${yy}`;
  }

  const formattedStartDate = formatDateString(startDateStr);
  const formattedEndDate = formatDateString(endDateStr);

  return `${formattedStartDate}–${formattedEndDate}`;
}

export function parseDateToMidnight(dateStr) {
  const [year, month, day] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day, 0, 0, 0, 0);
}
