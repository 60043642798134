import * as d3 from "d3";

export default class Themes {
  static themes = {
    default: {
      index: 0,
      name: "Default",
      scale: d3
        .scaleThreshold()
        .domain([0.3, 0.6, 0.8])
        .range(["#FF6978", "#FFCB77", "#9FD356", "#00A5FB"]),
    },
  };

  /**
   * Get the next theme given the current theme, returning the new theme and
   * rembering the new theme in the user's browser
   *
   * @param currentTheme
   * @returns
   */
  static toggleToNextTheme(currentTheme) {
    var newThemeKey = "default";
    Object.keys(Themes.themes).forEach(function (themeKey) {
      const theTheme = Themes.themes[themeKey];
      if (
        theTheme.index > currentTheme.index &&
        theTheme.index < currentTheme.index + 2
      ) {
        newThemeKey = themeKey;
      }
    });

    Themes.setCurrentD3Theme(newThemeKey);

    return Themes.getTheme(newThemeKey);
  }

  /**
   * Get a theme given a key identifying which it is
   *
   * @param themeKey
   * @returns
   */
  static getTheme(themeKey) {
    return Themes.themes[themeKey] || Themes.themes.default;
  }

  /**
   * Get the current D3 theme based on the browser's local storage
   *
   * @returns
   */
  static getCurrentD3Theme() {
    const cacheKey = "d3CurrentThemeKey";
    var themeKey = "";
    if (typeof Storage !== "undefined") {
      themeKey =
        window.sessionStorage.getItem(cacheKey) ||
        window.localStorage.getItem(cacheKey) ||
        "default";
    }

    return this.getTheme(themeKey);
  }

  /**
   * Store a theme key as the current theme in the browser's local storage
   *
   * @param themeKey
   */
  static setCurrentD3Theme(themeKey) {
    const cacheKey = "d3CurrentThemeKey";
    if (typeof Storage !== "undefined") {
      window.sessionStorage.setItem(cacheKey, themeKey);
      window.localStorage.setItem(cacheKey, themeKey);
    }
  }
}
