import { get } from "../../api/laravelApi";
import { unitReportEndpoint } from "../../constants";

export const UnitsLoader = async (request) => {
  const baseUrl = new URL(request.url);
  const startDate = baseUrl.searchParams.get("startDate");
  const endDate = baseUrl.searchParams.get("endDate");
  const aggregation = baseUrl.searchParams.get("aggregationIds");
  const facilityIds = baseUrl.searchParams.getAll("facilitiesIds");
  const customerIds = baseUrl.searchParams.getAll("customersIds");
  const unitIds = baseUrl.searchParams.getAll("unitsIds");
  const unitTypeIds = baseUrl.searchParams.getAll("UnitTypesIds");
  const roomIds = baseUrl.searchParams.getAll("roomsIds");

  const unitReportData = await get(unitReportEndpoint, {
    startDate,
    endDate,
    aggregation,
    facilityIds,
    customerIds,
    unitIds,
    unitTypeIds,
    roomIds,
  });

  return unitReportData;
};
