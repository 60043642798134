import { missingDataAction, missingDataMessage } from "../../constants";
import { formatPercentage } from "../../utils";
import ComplianceBucketButtons from "../ComplianceBucketButtons/ComplianceBucketButtons";
import useThresholdButtons from "../Filters/hooks/useThresholdButtons";
import MissingData from "../MissingData/MissingData";
import { complianceThresholds } from "../../constants";

function GroupTypePerformance({ data, category, id }) {
  const { selectedButton, setSelectedButton } = useThresholdButtons(data);

  const getClassName = (performance: number | null) => {
    if (performance === null) return "no-data-compliance";

    if (selectedButton === null) {
      for (const threshold of Object.values(complianceThresholds)) {
        const { min, max, className } = threshold;
        if (performance >= min && performance < max) {
          return className;
        }
      }
      return "no-data-compliance";
    }

    const { min, max, className } = complianceThresholds[selectedButton] || {};
    return performance >= min && performance < max
      ? className
      : "no-data-compliance";
  };

  const getText = (performance: number | null) => {
    if (getClassName(performance) === "no-data-compliance") {
      return "";
    } else {
      return `${performance}%`;
    }
  };

  const renderCell = (
    facilityName: string | number,
    performance: number | null
  ) => (
    <td
      key={facilityName}
      data-label={facilityName} /* This is for mobile view */
      className={
        performance !== null ? getClassName(performance) : "no-data-compliance"
      }
    >
      {getText(performance)}
    </td>
  );

  const renderRows = () => {
    return data?.map(({ type, ...performances }, index: number) => (
      <tr key={index}>
        <td>{type}</td>
        {Object.entries(performances).map(([facilityName, performance]) =>
          renderCell(facilityName, formatPercentage(performance))
        )}
      </tr>
    ));
  };

  const facilityKeys = Array.from(
    new Set(
      data?.flatMap((item: {}) =>
        Object.keys(item).filter((key) => key !== "type")
      )
    )
  ) as string[];

  return (
    <>
      <div className="margin-bottom">
        <ComplianceBucketButtons
          selectedButton={selectedButton}
          onSelectButton={setSelectedButton}
        />
      </div>
      {data && data.length === 0 && (
        <MissingData message={missingDataMessage} action={missingDataAction} />
      )}
      <div className="table-container" id={id}>
        <table className="opportunities-table">
          <thead>
            <tr>
              <th>{`${category} Type`}</th>
              {
                facilityKeys.map((facilityKeys: string) => (
                  <th key={facilityKeys}>{facilityKeys}</th>
                )) as any
              }
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </>
  );
}

export default GroupTypePerformance;
