import Cards from "../UI/Cards/Cards";

import Moon from "@untitled-ui/icons-react/build/cjs/Moon02";
import Clock from "@untitled-ui/icons-react/build/cjs/Clock";
import Sun from "@untitled-ui/icons-react/build/cjs/CloudSun01";
import Weekday from "@untitled-ui/icons-react/build/cjs/CalendarHeart01";
import Weekend from "@untitled-ui/icons-react/build/cjs/CalendarHeart02";
import Entry from "@untitled-ui/icons-react/build/cjs/ArrowCircleBrokenRight";
import Exit from "@untitled-ui/icons-react/build/cjs/ArrowCircleBrokenLeft";
import Tooltips from "../UI/Tooltip/Tooltip";
import { tooltipContent } from "../../content";
import Alert from "@untitled-ui/icons-react/build/cjs/AlertTriangle";
import MissingData from "../MissingData/MissingData";
import { missingDataAction, missingDataMessage } from "../../constants";

type OtherMetricsHHProps = {
  weekday: string;
  weekend: string;
  shiftData: Object;
  entry: string;
  exit: string;
};

function getIcon(key: string) {
  if (key.includes("Day")) {
    return <Sun />;
  } else if (key.includes("Night")) {
    return <Moon />;
  } else {
    return <Clock />;
  }
}

function OtherMetricsHH(props: OtherMetricsHHProps) {
  if (props.weekday === "No Data" && props.weekend === "No Data") {
    return (
      <>
        <Cards
          headerLeft={<h2>Performance Breakdown</h2>}
          headerRight={
            <Tooltips
              id={"otherMetricsHH-tooltip"}
              content={tooltipContent.otherMetricsHH["en-US"]}
            ></Tooltips>
          }
        >
          <div className="graph-text-center">
            <MissingData
              message={missingDataMessage}
              action={missingDataAction}
            />
          </div>
        </Cards>
      </>
    );
  }

  return (
    <>
      <Cards
        headerLeft={<h2>Performance Breakdown</h2>}
        headerRight={
          <Tooltips
            id={"otherMetricsHH-tooltip"}
            content={tooltipContent.otherMetricsHH["en-US"]}
          ></Tooltips>
        }
      >
        <div className="display-flex-space-between">
          <div className="center-content">
            <h2>Weekday</h2>
            <div className="display-flex-row">
              <div className="metrics-svg">
                {" "}
                <Weekday />
              </div>
              <h1>{props.weekday}</h1>
            </div>
          </div>
          <div className="center-content">
            <h2>Weekend</h2>
            <div className="display-flex-row">
              <div className="metrics-svg">
                {" "}
                <Weekend />
              </div>
              <h1>{props.weekend}</h1>
            </div>
          </div>
          {Object.keys(props.shiftData).length !== 0 ? (
            Object.entries(props.shiftData).map(
              ([key, value]: [string, string], index) => {
                return (
                  <div key={index} className="center-content">
                    <h2>{key}</h2>
                    <div className="display-flex-row">
                      <div className="metrics-svg">{getIcon(key)}</div>
                      <h1>{value}</h1>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="center-content">
              <h2>Shift Data</h2>
              <div className="display-flex-row">
                <div className="metrics-svg">
                  <Alert />
                </div>
                <h1>No Data</h1>
              </div>
            </div>
          )}
          <div className="center-content">
            <h2>Entry</h2>
            <div className="display-flex-row">
              <div className="metrics-svg">
                <Entry />
              </div>
              <h1>{props.entry}</h1>
            </div>
          </div>

          <div className="center-content">
            <h2>Exit</h2>
            <div className="display-flex-row">
              <div className="metrics-svg">
                <Exit />
              </div>
              <h1>{props.exit}</h1>
            </div>
          </div>
        </div>
      </Cards>
    </>
  );
}

export default OtherMetricsHH;
