import { complianceThresholds } from "../../constants";

function ComplianceBucketButtons({ selectedButton, onSelectButton }) {
  const handleClick = (id: number) => {
    if (selectedButton === id) {
      onSelectButton(null);
    } else {
      onSelectButton(id);
    }
  };

  return (
    <div>
      {Object.entries(complianceThresholds).map(([_key, button]) => (
        <button
          data-testid={"compliance-button-" + button.id}
          key={button.id}
          className={
            selectedButton === button.id
              ? "active-legend-button margin-right"
              : "margin-right"
          }
          onClick={() => handleClick(button.id)}
        >
          <div className={`compliance ${button.className}`}></div>
          {button.label}
        </button>
      ))}
    </div>
  );
}

export default ComplianceBucketButtons;
