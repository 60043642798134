import { useState, useEffect } from "react";
import { complianceThresholds } from "../../../constants";

function useThresholdButtons(data: unknown) {
  const [buttonData, setButtonData] = useState(data);
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [thresholds, setThresholds] = useState({
    thresholdOne: 0,
    thresholdTwo: 100,
  });

  useEffect(() => {
    if (
      selectedButton !== null &&
      selectedButton >= 0 &&
      selectedButton < Object.keys(complianceThresholds).length
    ) {
      setThresholds(complianceThresholds[selectedButton]);
    } else {
      setThresholds({ thresholdOne: 0, thresholdTwo: 100 });
    }
  }, [selectedButton]);

  useEffect(() => {
    setButtonData(data);
  }, [data]);

  return {
    buttonData,
    selectedButton,
    setSelectedButton,
    thresholdOne: thresholds.thresholdOne,
    thresholdTwo: thresholds.thresholdTwo,
  };
}

export default useThresholdButtons;
